<template>
  <section class="section-profile">
    <h2 class="section-profile__title">
      Создание рассылки
    </h2>
    <div class="vSectionMailing__content">
      <div class="vSectionMailing__recipient">
        <h3>Кому отправлять сообщения в бот?</h3>
        <div class="vSectionMailing__radio">
          <div class="vSectionMailing__radio__cell">
            <label class="radio">
              <input
                v-model="role"
                type="radio"
                class="radio__input"
                value="ALL"
              >
              <div class="radio__label">Всем</div>
            </label>
          </div>
          <div class="vSectionMailing__radio__cell">
            <label class="radio">
              <input
                v-model="role"
                type="radio"
                class="radio__input"
                value="WAITER"
              >
              <div class="radio__label">Официант</div>
            </label>
          </div>
          <div class="vSectionMailing__radio__cell">
            <label class="radio">
              <input
                v-model="role"
                type="radio"
                class="radio__input"
                value="MANAGER"
              >
              <div class="radio__label">Админ</div>
            </label>
          </div>
        </div>
        <div class="line" />
        <div class="vSectionMailing__recipient-info">
          <h4 class="vSectionMailing__recipient-info__title">
            Выбор адресата
          </h4>
          <p class="vSectionMailing__recipient-info__content">
            Выбранной роли будут отправляться сообщения
          </p>
        </div>
      </div>
      <div class="vSectionMailing__message">
        <h3>Текст сообщения</h3>
        <div>
          <textarea
            ref="textarea"
            v-model="response_template"
            class="vSectionMailing__message__textarea"
            :class="{ active: response_template.length }"
            required
            @input="resize($event)"
          />
          <div class="vSectionMailing__label">
            Введите текст..
          </div>
        </div>
        <div class="line" />
        <div class="vSectionMailing__message-info">
          <h4 class="vSectionMailing__message-info__title">
            Текст сообщения
          </h4>
          <p class="vSectionMailing__message-info__content">
            В это поле вы можете вписать текст, который придёт выбранному
            адресату в телеграм бот
          </p>
        </div>
        <div class="line" />
        <div class="buttonForm">
          <vButton
            type="button"
            :disabled="disableButton"
            @click="toggleConfirm"
          >
            Отправить
          </vButton>
        </div>
      </div>
    </div>
    <VModalMailing
      v-if="dispatch"
      @toggleConfirm="toggleConfirm"
      @sendMessage="sendMessage"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vButton from '@/components/v-button'
import VModalMailing from '@/components/modals/v-modal-mailing'

export default {
  name: 'Mailing',
  components: {
    vButton,
    VModalMailing,
  },
  data() {
    return {
      role: 'ALL',
      disableButton: true,
      response_template: '',
      dispatch: false,
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  watch: {
    response_template() {
      this.disableButton = false
    },
    role() {
      this.disableButton = false
    },
  },
  mounted() {
    setTimeout(() => {
      this.changeData = false
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('telegram', ['MAILING_TELEGRAM']),

    resize(e) {
      e.target.style.height = 'auto'
      e.target.style.height = `${e.target.scrollHeight}px`
    },

    toggleConfirm() {
      this.dispatch = !this.dispatch
    },

    async sendMessage() {
      const req = {
        message: this.response_template,
        role: this.role,
      }
      await this.MAILING_TELEGRAM({
        token: this.GET_AUTHENTIFICATED,
        req,
      })
      this.response_template = ''
      this.$refs.textarea.style.height = 'auto'
      this.toggleConfirm()
    },

    async checkForm() {
      this.identicalMail = ''
      await this.TOGGLE_PRELOADER(true)
      await this.INVITATION({
        id: this.id,
        email: this.email,
        token: this.GET_AUTHENTIFICATED,
      })
      if (this.WAITERS_ERROR) {
        this.identicalMail = 'E-mail уже привязан к другому официанту'
      } else {
        this.statusWaiterLocal = 'INVITED'
      }
      this.TOGGLE_PRELOADER(false)
    },
  },
}
</script>

<style lang="scss">
.vSectionMailing {
  &__content {
    background-color: #fff;
    border-radius: 11px;
    border: 1px solid #e6e7eb;
    padding: 24px 24px 8px 24px;
    max-width: 636px;

    h3 {
      margin-bottom: 24px;
    }
  }
  &__recipient {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #010d35;
    position: relative;
  }
  &__radio {
    &__cell {
      margin-bottom: 20px;
    }
    .radio {
      position: relative;
      margin-bottom: 20px;
      &__input {
        position: absolute;
        appearance: none;
        -webkit-appearance: none;
      }
      &__label {
        padding-left: 35px;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #010d35;
        cursor: pointer;
        &:before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border: 3px solid #cccfd7;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          transition: border 0.1s linear;
        }
        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: #6764ff;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          left: 6px;
          z-index: 2;
          opacity: 0;
          transition: opacity 0.1s linear;
        }
      }
    }
    .radio__input:checked + .radio__label:before {
      background-color: #fff;
      border-color: blue;
    }
    .radio__input:checked + .radio__label:after {
      opacity: 1;
    }
  }
  &__recipient-info {
    max-width: 257px;
    position: absolute;
    top: 0;
    right: -54%;
    &__title {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    &__content {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 12px;
    }
  }
  &__message {
    margin-top: 16px;
    position: relative;
    &__textarea {
      position: relative;
      font-family: 'Commissioner';
      width: 100%;
      //max-height: 330px;
      min-height: 80px;
      //height: 80px;
      margin-bottom: 20px;
      padding: 24px 12px 8px 12px;
      border-radius: 12px;
      border: 1px solid #e6e7eb;
      outline: none;
      resize: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #010d35;
      &:is(:focus, :valid) {
        border: 1px solid #6764ff;
      }
      &::-webkit-scrollbar {
        width: 0;
      }
      &:focus + .vSectionMailing__label,
      &.active + .vSectionMailing__label {
        top: 60px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        z-index: 2;
      }
    }
  }
  &__message-info {
    max-width: 257px;
    position: absolute;
    top: 0;
    right: -54%;
    &__title {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    &__content {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #6764ff;
    }
  }
  &__label {
    position: absolute;
    top: 65px;
    left: 12px;
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    color: #80869a;
    transform: translateY(-50%);
    transition: all 0.3s;
    z-index: 2;
  }
}
.line {
  width: 100%;
  height: 1px;
  background-color: #edeef1;
}
.buttonForm {
  margin-top: 16px;
}
</style>
